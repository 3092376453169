import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import ContactUs from '../components/ContactUs'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import LocaleContext from '../contexts/LocaleContext'

export default function Contact({ data, pageContext }) {
  const { ourOffices, bottomPhoto } = data.datoCmsAboutUsPage

  return (
    <Layout locale={pageContext.locale}>
      <Menu
          transparent={false}
          menu={data.datoCmsNewMenu}
          socialMedia={data.datoCmsFooter.socialMedia}
        />
      <React.Fragment>
        <HelmetDatoCms>
          <title>Nordic Asia - Investment Advisory Group | About Us</title>
        </HelmetDatoCms>

        <div className="container center-width">
          <ContactUs data={data.datoCmsContact} />
        </div>

        <section className="container center-width prose">
          <div
            dangerouslySetInnerHTML={{
              __html: ourOffices,
            }}
            className="our-offices margin-bottom"
          ></div>
          <Follow />
          <div style={{ marginBottom: '3rem' }}>
            {data.datoCmsFooter.socialMedia.map((link) => (
              <a
                href={link.target}
                target="_blank"
                rel="noreferrer"
                className="margin-right-small icon"
                key={link.name}
              >
                <img src={link.icon.url} alt={link.name} />
              </a>
            ))}
          </div>
        </section>

        <Hero className="opacity-low" heroBackground={bottomPhoto}>
          <div className="prose">
            {/* <div 
                dangerouslySetInnerHTML={{
                __html: ourOffices,
                  }}
              ></div> */}
          </div>
        </Hero>
        <Footer footer={data.datoCmsFooter} />
      </React.Fragment>
    </Layout>
  )
}

const Follow = () => {
  const locale = React.useContext(LocaleContext).language
  return (
    <div className="margin-bottom-small">{locale=="sv" ? 'Följ oss:' : 'Follow us:' } </div>
  )
}

export const query = graphql`
  query ($locale: String!){
    datoCmsAboutUsPage (locale: { eq: $locale }) {
      ourOffices
      bottomPhoto {
        fluid(maxWidth: 1800, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsContact (locale: { eq: $locale }) {
      ...Contact
    }
    datoCmsNewMenu (locale: { eq: $locale }) {
      ...Menu
    }
    datoCmsFooter (locale: { eq: $locale }) {
      ...Footer
    }
  }
`
